<template>
  <v-row class="mt-5" justify="center" no-gutters>
    <v-col cols="10">
      <iframe
        style="position: absolute; width: 80%; height:100%"
        title="BI_INDGER_BRASIL"
        src="https://app.powerbi.com/reportEmbed?reportId=b66dcf72-d519-4a7a-92a0-66ddaa95d347&autoAuth=true&ctid=104d4551-2d27-4839-87b2-86264a5210a5"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: "DashboardPainelBrasil",
  };
</script>
